import React, {useEffect,useState} from "react";
import "../../assets/style.css";
import axios from 'axios';
import { API_Username, API_Password } from "../../Config";
import { Formik, Field, Form, useField } from 'formik';
import * as Yup from 'yup';
import CustomErrorMsg from "../../CustomErrorMsg";
import { useNavigate } from 'react-router-dom';

function Survey(){
    const [successMessage, setSuccessMessage] = useState();
    const [details, setDetails] = useState([]);
    const navigate = useNavigate();
    let counter = 0;

    function getSurveyQuestionList() {
        axios.get(`api/getSurveyQuestionlistFrontend`, { headers: {
            'Authorization': `Basic ${btoa(API_Username+':'+API_Password)}`,
            'Accept'       : 'application/json'
        }}).then(res => {
            setDetails(res.data.data);
        })
    }

    useEffect(() => {
        getSurveyQuestionList();
    }, []);

    return (
        <>
        <main>
            <div className="surveyQ-section">
                <div className="container">
                    <div className="surveyQusetBox">
                        <div className="surveyQusetBoxHeader">
                            <h2 className="qTitle">WHC Survey Questionnaire</h2>
                            <p>Thank you for taking the time to answer these questions.</p>
                        </div>
                        <div className="surveyQusetBoxBody">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    email: '',
                                    ...details.reduce((acc, item, index) => {
                                        acc[item.question_title] = '';
                                        return acc;
                                    }, {})
                                }}
                                validationSchema={Yup.object({
                                    email: Yup.string().required('Email is required'),
                                    ...details.reduce((acc, item, index) => {
                                        acc[item.question_title] = item.question_type === 'Multi-option' ?
                                            Yup.string().required('Select an option')
                                            :
                                            Yup.string().required('Answer is required');
                                        return acc;
                                    }, {})
                                })}

                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    setSubmitting(true);
                                    axios.post(`api/create-survey-feedback`, values).then(res => {
                                        if(res.data.status === true) {
                                            details.forEach((item, index) => {
                                                if (item.question_type !== 'Multi-option') {
                                                    document.getElementsByName(item.question_title)[0].value = '';
                                                }
                                            });
                                            resetForm();
                                            navigate("/confirmation");
                                        }
                                    })

                                }}
                            >
                                <Form>
                                {details ? details.map((item, index) => (
                                    <div className="formGrp">
                                        <p>{item.id}. {item.question_title}</p>
                                        {item.question_type=='Multi-option' ? 
                                            <div className="inputDiv">
                                                
                                                {JSON.parse(item.question_type_value) && JSON.parse(item.question_type_value).map((item1, index1) => {
                                                counter++;
                                                    return (
                                                        <React.Fragment key={index1}>
                                                            <p>
                                                            <Field type="radio" id={`radio_${counter}`} name={item.question_title} value={`${item1}`} />
                                                            <label htmlFor={`${counter}_${item1}`}>{`${item1}`}</label>
                                                            </p>
                                                            
                                                        </React.Fragment>
                                                    );
                                                })}
                                                <CustomErrorMsg name={item.question_title} />
                                                
                                            </div>
                                            :
                                            <>
                                                <Field as="textarea"
                                                    name={item.question_title}
                                                    rows={5}
                                                    className="form-control"
                                                ></Field>
                                                
                                            </>
                                        }
                                    </div>
                                )) : null}
                                    <div className="formGrp">
                                        <p>Your Email Address:</p>
                                        <Field type="email" className="form-control" name="email"/>
                                        <CustomErrorMsg name="email" />
                                    </div>
                                    <div>
                                    {details ? details.map((item, index) => (
                                    <Field key={index} type="text" className="form-control" name={`question_title_${index + 1}`} value={item.question_title} style={{ display: 'none' }} />
                                    )) : null}
                                    </div>
                                    <div className="formGrp">
                                        <button type="submit" className="btn btn_sub">Submit</button>
                                    </div>
                                    {successMessage && <p className='email_Message'>{successMessage} </p>}
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    )
}

export default Survey