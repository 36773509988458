import React from 'react';
import like from '../../assets/images/great.png';
import { Link } from "react-router-dom";

const Confirmation = () => {
    return (
        <div className='confirmationPage'>
            <div className='pageContainer'>
                <div className='confirmInner text-center'>
                    <span className='like'><img src={like} alt='' /></span>
                    <h5>Thanks for submitting your feedback!</h5>
                    <p>Your feedback is essential to us. We appreciate your input.</p>
                    <Link to="/" className='backBtn'>Back To Home</Link>
                </div>
            </div>
        </div>
        
    )
}

export default Confirmation;