import React, {useEffect} from "react";
import axios from 'axios';
import { BrowserRouter, Route, Routes, useRoutes } from "react-router-dom";
import Survey from "../src/components/frontend/Survey";
import { API_URL, API_Username, API_Password } from "./Config";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Confirmation from "./components/frontend/Confirmation";


function App() {
  axios.defaults.baseURL =
  API_URL+"whc-custom/public/";
  axios.defaults.headers.post["Authorization"] =
  "Basic " + btoa(API_Username+':'+API_Password);

  useEffect(() => {
    console.log("Basic " + btoa(API_Username+':'+API_Password));
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Survey />} />
          <Route path="/confirmation" element={<Confirmation />} />
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
