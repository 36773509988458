let BASE_URL= window.location.origin;
let API_URL = "https://wholesalehealthclub.com/"; // http://localhost.staging-wholesalehealthclub.com/
let API_Username = "7Ty3VeymqZK1qe0";
let API_Password = "5t0HTlMn%x0D";

const Config = () => {


}

export {BASE_URL, API_URL, API_Username, API_Password};