import { Link } from "react-router-dom"

function Footer(){
    const currentYear = new Date().getFullYear();
    return (
        <footer className="mainFooter">
            <div className="mainFooter_content text-center">
                <div className="container-fluid">
                    <ul className="f_quick_links">
                        <li>
                            <Link to='#' onClick={(event) => {
                                event.preventDefault();
                                window.open('https://wholesalehealthclub.com/term-and-condition', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                            }}>Terms And Conditions | </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(event) => {
                                event.preventDefault();
                                window.open('https://wholesalehealthclub.com/privacy-policy', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                            }}>Privacy Policy | </Link>
                        </li>
                        <li>
                            <Link to="#" onClick={(event) => {
                                event.preventDefault();
                                window.open('https://wholesalehealthclub.com/citations', '_blank', 'toolbar=no,scrollbars=no,resizable=no,width=400,height=400');
                            }}>Citations | </Link>
                        </li>
                        <li>
                            <Link to='https://wholesalehealthclub.com/contact-us'> Contact Us </Link>
                        </li>
                    </ul>
                    <p>Copyright © {currentYear} | Wholesale Health Club | PO BOX 90129 Lakeland FL 33804 | Billing <br /> descriptor:
                        wholesalehealthclubcom | Customer Service&nbsp;<span>Phone: <a
                                href="tel:(480)573-0829">(480)573-0829</a></span> <span> | Customer Service Email: <br /><a
                                href="mailto:support@wholesalehealthclub.com">support@wholesalehealthclub.com</a></span></p>
                    
                </div>
            </div>
        </footer>
    )
}

export default Footer