import "../assets/style.css";
import logo from  '../assets/images/wholesaleHealthClub.png';
import { Link } from "react-router-dom";


function Header(){
    return (
        <header>
            <div className='logo'>
                <Link to='https://wholesalehealthclub.com'><img src={logo}  className='logoImg' /></Link>
            </div>
        </header>
    )
}

export default Header